import React, { useEffect } from 'react';
import lc_icon from '../../src/images/laundry_care_icon.png';
import pl_icon from '../../src/images/push_laundry_icon.png';
import { useNavigate } from 'react-router-dom';
export default function AndroidLanding() {
  const navigate = useNavigate();
  const [URLFromGoogle, setURLFromGoogle] = React.useState(null);
  useEffect(() => {
    const tempCode = window.location.href.split('code=')[1];
    if (tempCode) {
      setURLFromGoogle(window.location.href);
    } else {
      navigate('/signin');
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      import.meta.env.VITE_TENANT_NAME === 'laundrycare'
        ? (window.location.href = 'com.taplaundry.laundrycare://' + URLFromGoogle)
        : (window.location.href = 'com.yahoo.mathacedh.Push_Laundry://' + URLFromGoogle);
    }, 1000);
  }, [URLFromGoogle]);
  return (
    <div className='h-screen'>
      <div className='flex p-10'>
        {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
          <img src={lc_icon} alt='laundry care icon' className='w-12' />
        ) : (
          <img src={pl_icon} alt='push laundry icon' className=' w-12' />
        )}
      </div>
      <div className='mt-24 flex items-center'>
        <div className='m-auto '>
          <div className='px-4 text-center'>
            <div className='mb-2 inline-flex'>
              <div aria-label='Loading...' role='status'>
                <svg className='h-12 w-12 animate-spin stroke-gray-500' viewBox='0 0 256 256'>
                  <line
                    x1='128'
                    y1='32'
                    x2='128'
                    y2='64'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                  <line
                    x1='195.9'
                    y1='60.1'
                    x2='173.3'
                    y2='82.7'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                  <line
                    x1='224'
                    y1='128'
                    x2='192'
                    y2='128'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                  <line
                    x1='195.9'
                    y1='195.9'
                    x2='173.3'
                    y2='173.3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                  <line
                    x1='128'
                    y1='224'
                    x2='128'
                    y2='192'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                  <line
                    x1='60.1'
                    y1='195.9'
                    x2='82.7'
                    y2='173.3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                  <line
                    x1='32'
                    y1='128'
                    x2='64'
                    y2='128'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                  <line
                    x1='60.1'
                    y1='60.1'
                    x2='82.7'
                    y2='82.7'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='24'
                  ></line>
                </svg>
              </div>
            </div>
            <div className='go-textnormal-20 font-bold'>redirecting to app...</div>
          </div>
        </div>
      </div>
    </div>
  );
}
